import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation/"
            }}>{`Lethargic Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/li-universal-investment-strategy/"
            }}>{`Universal Investment Strategy`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: mean-variance optimization strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking equities, fixed income, and hard assets`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 80% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $10,000`}</li>
      </ul>
      <p>{`Wouter J. Keller is a researcher at the VU University Amsterdam and the CEO of Flex Capital, an asset management firm based in the Netherlands. Together with Adam Butler and Ilya Kipnis, he published the `}<em parentName="p">{`Classical Asset Allocation`}</em>{` (CAA) strategy in a paper released in May 2015.`}</p>
      <p>{`Keller's `}<em parentName="p">{`Classical Asset Allocation`}</em>{` aims to create an optimally diversified portfolio which maximizes return for a given level of risk, but with a tactical twist. To achieve this goal, Keller's strategy performs mean-variance optimization over a lookback period of 12 months with a set of 8 ETFs spanning all major asset classes.`}</p>
      <p><em parentName="p">{`CAA`}</em>{` rebalances once per month, making the strategy low maintenance.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2606884"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "602px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "140.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACX0lEQVQ4y51V7W6jMBDs+79e1RT8tdhgUsDERQ4QMJA7sVdDaKqTOj8ix/LsjndnzYtzzns//IzxGbz3bdu+9H1//xXGcXzpuu5+vzdNk+d5WZZa67Isi6I4n89a69vt9hN5GIZ/5KIo4jgmhLy/v1NKoyhSSjnn5nn+P7lpmqIo5i9M04QnlmWZ53n5Aq6fZMZsQogkSaSUaZpyzvGvEEJKmSQJ51xrfSQbYzjnSikAEEIAAJ4GACRzznFTStm27QO5rmtCCMYGAEopkoMQKSUApGlqjDmSnXNa6zzPi6K4Xq/dinaHruv6vsdfrOJGttYyxpIVUkq8JKpA5bgJAB8fH2iNjVxV1el0IisopYQQsQKVYwi+4nK5bJkxzPV6LcvSGFNVVV3Xxpjb7ea9n6bJ7zCO4zAMR9nOOQDIsgyrspeaZVkomxDiiWxjzNvbGwqOoiiO4yiK9oaL45gxhqGPstu2Rdl75WVZVlVVrjifz1VV4Zyh/7bMl8sFFXLOgz0AgBASx3FIjkYax/FoEkopOowxhk0KCyyBMSaU+kH2size+67rxnHcDwMu9vPwMFVInufZOZfnubUWTweEKNM0hc3jndHPSim8NjqMrsBpwYZRSpumObbq9fUV7QUASikpJfYmWFUIwRjL83wYhietstYaY7qu+24s9BY+fcd53o8kY4xSinlQSJhqANBaY/G2zH3f13XdrLDW7tcoEou0r/lG9t73K3BiwxuGNKzztOKhzyj78/NTCJGmKQ4A1ixdgS3IskxrrZSy1j4xSUiF6/Bift/cvP3rL8Zf8h8/VCoob2Zc4wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Wouter J. Keller, Adam Butler, Ilya Kipnis, Momentum and Markowitz: A Golden Combination",
                "title": "Wouter J. Keller, Adam Butler, Ilya Kipnis, Momentum and Markowitz: A Golden Combination",
                "src": "/static/70b8d9c93e3e615514e929cca847841a/32056/keller-classical-asset-allocation.png",
                "srcSet": ["/static/70b8d9c93e3e615514e929cca847841a/5a46d/keller-classical-asset-allocation.png 300w", "/static/70b8d9c93e3e615514e929cca847841a/0a47e/keller-classical-asset-allocation.png 600w", "/static/70b8d9c93e3e615514e929cca847841a/32056/keller-classical-asset-allocation.png 602w"],
                "sizes": "(max-width: 602px) 100vw, 602px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2606884"
            }}>{`Momentum and Markowitz`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527"
            }}>{`Wouter J. Keller`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://investresolve.com/adam-butler/"
            }}>{`Adam Butler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://quantstrattrader.wordpress.com/about/"
            }}>{`Ilya Kipnis`}</a></li>
        </ul>
        <p><strong parentName="p">{` Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Modern_portfolio_theory"
            }}>{`Mean-Variance Optimization`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2197616"
            }}>{`Critical-Line Algorithm`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/Keller_CAA.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`Keller’s strategy comes in multiple flavors, which mostly differ in the size of their universes. Our implementation of the strategy matches the publication’s `}<em parentName="p">{`CAA-N=8-TV=5%`}</em>{` variant. The rules for this strategy can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade equities from 5 different markets, high-yield bonds, 10-year Treasuries, and T-bills`}</li>
        <li parentName="ul">{`rebalance monthly`}</li>
        <li parentName="ul">{`estimate returns and the covariance matrics of all assets over a lookback period of 12 months`}</li>
        <li parentName="ul">{`calculate the Efficient Frontier using Markowitz' Critical Line Algorithm`}</li>
        <li parentName="ul">{`find the portfolio with 5% annualized target-volatility along the Efficient-Frontier or the Capital Market Line`}</li>
      </ul>
      <p>{`For full detail and the rationale behind `}<em parentName="p">{`Classical Asset Allocation`}</em>{` refer to Keller's fabulous paper. Further, the C# source code for the strategy is included in the `}<em parentName="p">{`TuringTrader.org`}</em>{` open-source project repository.`}</p>
      <p>{`The central concept of the `}<em parentName="p">{`Classical Asset Allocation`}</em>{` is using Mean-Variance Optimization to construct portfolios. This concept dates back to 1952 and Harry Markowitz’s work on modern portfolio theory. Unlike this original work, which uses long-term estimates for returns and covariance, `}<em parentName="p">{`CAA`}</em>{` applies mean-variance optimization to 12-months rolling periods. We like this concept a lot, but unfortunately see issues with it.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Classical Asset Allocation`}</em>{` focuses on optimal diversification and achieving low volatility. Because this approach shows its real value only in times of market turmoil, investors should contemplate performance over the full economic cycle.`}</p>
      <p>{`Mean-Variance Optimization promises to maximize the advantages of diversification. Unfortunately, `}<em parentName="p">{`CAA`}</em>{` does not always achieve that. In our testing, we found that the methodology tends to flip-flop between assets, in cases where these are highly correlated and of similar momentum. Under these circumstances, it seems including all of the affected assets would lead to more stable allocations and better diversification.`}</p>
      <p>{`Further, we find that in situations where the volatility of assets is around or below the target volatility, `}<em parentName="p">{`Classical Asset Allocation`}</em>{` degenerates to momentum-ranking. In contrast to the original intent, this behavior does not account for tail risk and changes in volatility. `}<em parentName="p">{`CAA`}</em>{` attempts to mitigate this by setting maximum weights for risky assets, but we believe this to be an incomplete solution.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Over the last economic cycle, beginning shortly before the 2008 recession, the strategy has done a remarkable job in managing volatility. Especially, the Monte-Carlo simulation shows a risk profile, which is consistently below that of a passive portfolio.`}</p>
      <p>{`Unfortunately, this smooth performance comes at a price: In absolute terms, `}<em parentName="p">{`Classical Asset Allocation`}</em>{`  underperforms a passive 60/40. However, `}<em parentName="p">{`CAA`}</em>{`'s risk-adjusted returns are significantly higher, making the strategy very appropriate in situations where capital preservation is key.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Similar to other strategies rotating assets, Keller’s strategy will frequently cause taxable events. However, `}<em parentName="p">{`CAA`}</em>{` does stay invested in some winners long enough to qualify for long-term treatment of capital gains. Still, `}<em parentName="p">{`CAA`}</em>{` will work best in tax-deferred accounts.`}</p>
      <p><em parentName="p">{`Classical Asset Allocation`}</em>{` invests in up to 8 ETFs simultaneously. To do so, it requires an allocation of no less than $10,000 to function correctly.`}</p>
    </Review>
    <Notes mdxType="Notes">
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      